import * as React from "react";
import "./clickable-blog-article.scss";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";
import {Link} from "gatsby-plugin-react-i18next";

interface ClickableBlogArticleProps {
    title: string;
    description: string;
    date: Date;
    tags: string[];
    featuredImage: IGatsbyImageData;
    slug: string;
}

const ClickableBlogArticle = (props: ClickableBlogArticleProps) => {
    const tags = props.tags.join(' ')
   
    return (
        <Link className="clickable-blog-article" to={props.slug}>
            <GatsbyImage alt={props.title} image={props.featuredImage}/>
            <div className="clickable-blog-article_article-info">
                <span>{props.date.toString()}</span>
                <span>{tags}</span>
            </div>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
        </Link>
    )
}

export default ClickableBlogArticle;