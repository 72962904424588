import React from "react";
import {useSiteMetadata} from "../../hooks/use-site-metadata";
import {Helmet} from "gatsby-plugin-react-i18next";

export const SEO = ({title, description, pathname, children}: any) => {
    const {title: defaultTitle, description: defaultDescription, lang, siteUrl} = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
        lang: lang
    };

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description}/>
            {children}
        </>
    )
}