import * as React from "react";
import "./blog-articles.scss";
import {graphql, useStaticQuery} from "gatsby";
import ClickableBlogArticle from "./ClickableBlogArticle/clickable-blog-article";


const BlogArticles = () => {
    const {allMarkdownRemark: {nodes}} = useStaticQuery(graphql`
        query {
            allMarkdownRemark
            (
                sort: { fields: [frontmatter___date], order: DESC }
                limit: 100
            ){
                nodes {
                    fields {
                        slug
                    }
                    excerpt(pruneLength: 300)
                    frontmatter {
                        title
                        date
                        description
                        tags
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData (aspectRatio:1.78 )
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <section className="blog-articles">
            {nodes.map((article: any) =>
                <ClickableBlogArticle
                    slug={article.fields.slug}
                    title={article.frontmatter.title}
                    date={article.frontmatter.date}
                    description={article.excerpt}
                    tags={article.frontmatter.tags}
                    featuredImage={article.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                />)}
        </section>
    )
}

export default BlogArticles;
