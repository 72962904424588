import {graphql, useStaticQuery} from "gatsby"
import {ReactChildren} from "react";

export const useSiteMetadata = () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteUrl
                        lang
                    }
                }
            }
        `);

    return data.site.siteMetadata
}