import * as React from "react"
import type { HeadFC } from "gatsby"
import Navbar from "../components/Navbar/navbar";
import {graphql} from "gatsby";
import {NegativeSpace} from "../components/NegativeSpace/negative-space";
import BlogArticles from "../components/BlogArticles/blog-articles";
import Footer from "../components/Footer/footer";
import SubPageTitle from "../components/SubPageTitle/sub-page-title";
import {useTranslation} from "react-i18next";
import {SEO} from "../components/Seo/seo";


const BlogPage = () => {
    const {t} = useTranslation();
    return (
        <main>
            <SubPageTitle title={t('blog.title')} subtitle={t('blog.subtitle')}/>
            <NegativeSpace/>
            <BlogArticles/>
            <NegativeSpace/>
        </main>
    )
}

export default BlogPage;

export const Head: HeadFC = () => <SEO title={"Markana - blog"} />

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;