import * as React from "react";
import "./sub-page-title.scss";
import {Linolade} from "../Linolade/linolade";
import Container from "../Container/container";

interface SubPageTitleProps {
    title: string;
    subtitle: string;
}

const SubPageTitle = (props: SubPageTitleProps) => {
    return (
        <section className="sub-page-title">
            <h1>{props.title}</h1>
            <Container>
                <h5>{props.subtitle}</h5>

            </Container>
        </section>
    )
}

export default SubPageTitle;